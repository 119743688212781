function getCurrentCacheTime() {
  const now = new Date()
  now.setUTCMinutes(Math.floor(now.getUTCMinutes() / 5) * 5)
  return now.toISOString().slice(0, 16)
}

export function clearCachedHostResults() {
  if (typeof localStorage === 'undefined') return null
  Object.keys(localStorage).map(key => {
    if (key.match(/^results:/)) {
      localStorage.removeItem(key)
    }
  })
}

export function getCachedHostResults(hostId: string, days: number, start: number) {
  if (typeof localStorage === 'undefined') return null
  if (!days) days = 1
  if (!start) start = 0
  const cacheTime = getCurrentCacheTime()
  const cacheKey = 'results:' + hostId + ':' + days + ':' + start
  // console.log('Loading cached results for', hostId, days, cacheTime, 'from', cacheKey)
  const cachedResults = localStorage.getItem(cacheKey)
  if (!cachedResults) return null
  try {
    const item = JSON.parse(cachedResults)
    if (item.time === cacheTime && item.results) {
      return item.results
    }
    return null
  } catch (err) {
    console.log('WARNING: ERROR PARSING CACHED RESULTS:', err)
    return null
  }
}

export function setCachedHostResults(hostId: string, days: number, start: number, results: any) {
  if (typeof localStorage === 'undefined' || !results) return null
  if (!days) days = 1
  if (!start) start = 0
  const cacheTime = getCurrentCacheTime()
  const cacheKey = 'results:' + hostId + ':' + days + ':' + start
  // console.log('Saving cached results for', hostId, days, cacheTime, 'to', cacheKey)
  localStorage.setItem(cacheKey, JSON.stringify({
    time: cacheTime,
    results: results,
  }))
}