/**
 * Mail context.
 */
import React from 'react'
import config from 'react-global-configuration'
import { apiFetchWithAccessToken } from 'clouden-components/context/lib/api-fetch'

export const MailContext = React.createContext(null)
export const MailConsumer = MailContext.Consumer

interface MailStoreProps {
  children: any
}

export interface MailContext {
  preferences: any
  setMailPreference: Function
  fetchMailPreferences: Function
  saveMailPreferences: Function
}

export class MailStore extends React.Component<MailStoreProps> {
  state = {
    preferences: {} as any,
  }

  async componentDidMount() {
  }

  async setMailPreference(serviceId: string, key: string, value: string) {
    const preferences = { ...this.state.preferences }
    if (!preferences[serviceId]) {
      preferences[serviceId] = {}
    }
    preferences[serviceId][key].value = value
    this.setState({
      preferences: preferences
    })
    return {
      serviceId: serviceId,
      key: key,
      value: value,
    }
  }

  async fetchMailPreferences(serviceId: string) {
    const url = config.get('MAIL_API_URL') + '/services/' + serviceId + '/preferences'
    const response = await apiFetchWithAccessToken('GET', url)
    const preferences = { ...this.state.preferences }
    preferences[serviceId] = response
    this.setState({
      preferences: preferences
    })
    return response
  }

  async saveMailPreferences(serviceId: string, servicePreferences: any) {
    const url = config.get('MAIL_API_URL') + '/services/' + serviceId + '/preferences'
    const response = await apiFetchWithAccessToken('PUT', url, servicePreferences)
    const preferences = { ...this.state.preferences }
    preferences[serviceId] = response
    this.setState({
      preferences: preferences
    })
    return response
  }

  render() {
    return (
      <MailContext.Provider value={{ ...this.state,
        setMailPreference: this.setMailPreference,
        fetchMailPreferences: this.fetchMailPreferences,
        saveMailPreferences: this.saveMailPreferences,
      }}>
        {this.props.children}
      </MailContext.Provider>
    )
  }
}

export function withMailContext(Comp: any) {
  return (props: any) => (
    <MailContext.Consumer>
      {(mailContext: MailContext) => <Comp {...props} mailContext={{...mailContext}}/>}
    </MailContext.Consumer>
  )
}
