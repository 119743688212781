import i18n from 'i18next'
import fiMessages from './translations/fi.json'
import fiComponentMessages from 'clouden-components/translations/fi.json'
import enAmplifyMessages from 'clouden-components/translations/en-amplify.json'
import fiAmplifyMessages from 'clouden-components/translations/fi-amplify.json'
import { I18n } from 'aws-amplify'
import qs from 'qs'

const DEFAULT_LANG = 'en'
const LANGUAGE = typeof window !== 'undefined' && window.location && window.location.hostname === 'verkkovahti.fi' ? 'fi' : DEFAULT_LANG

i18n.init({
  fallbackLng: 'en',
  lng: LANGUAGE,
  debug: false, //IS_LOCALHOST,
  interpolation: {
    // React already does escaping
    escapeValue: false,
  },
  resources: {
    fi: {
      translation: Object.assign({}, fiMessages, fiComponentMessages),
    },
  },
  react: {
    defaultTransParent: 'span',
  },
})

/* AWS Amplify */
I18n.setLanguage(LANGUAGE)
I18n.putVocabularies({
  en: enAmplifyMessages,
  fi: fiAmplifyMessages,
})

export default i18n
