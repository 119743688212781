// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/home/coder/project/clouden/clouden-ping/ui/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("/home/coder/project/clouden/clouden-ping/ui/src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("/home/coder/project/clouden/clouden-ping/ui/src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-edit-host-tsx": () => import("/home/coder/project/clouden/clouden-ping/ui/src/pages/edit-host.tsx" /* webpackChunkName: "component---src-pages-edit-host-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/coder/project/clouden/clouden-ping/ui/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-results-tsx": () => import("/home/coder/project/clouden/clouden-ping/ui/src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */),
  "component---src-pages-support-tsx": () => import("/home/coder/project/clouden/clouden-ping/ui/src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-user-tsx": () => import("/home/coder/project/clouden/clouden-ping/ui/src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/coder/project/clouden/clouden-ping/ui/.cache/data.json")

