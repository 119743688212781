/**
 * Ping context.
 */
import React from 'react'
import config from 'react-global-configuration'
import { getCachedHostResults, setCachedHostResults, clearCachedHostResults } from './result-cache'
import { apiFetchWithAccessToken } from 'clouden-components/context/lib/api-fetch'

export const PingContext = React.createContext(null)
export const PingConsumer = PingContext.Consumer

interface PingStoreProps {
  children: any
}

export interface PingContext {
  hosts: any[]
  host: any
  hostResults: any[]
  hostResultsMap: any
  loadingHosts: boolean
  loadingHost: boolean
  loadingHostResults: boolean
  fetchHosts: Function
  fetchHost: Function
  fetchHostResults: Function
  clearHostResults: Function
  clearHostCache: Function
  updateHost: Function
  deleteHost: Function
  postPaymentNotification: Function
}

export class PingStore extends React.Component<PingStoreProps> {
  state = {
    hosts: [],
    host: null,
    hostResults: [],
    hostResultsMap: {} as any,
    loadinghosts: false,
    loadingHost: false,
    loadingHostResults: false,
  }

  async componentDidMount() {
  }

  fetchHosts = async () => {
    this.setState({
      loadingHosts: true,
    })
    try {
      const response = await apiFetchWithAccessToken('GET', config.get('PING_API_URL') + '/hosts')
      this.setState({
        hosts: response,
        loadingHosts: false,
      })
      return response
    } catch (err) {
      this.setState({
        loadingHosts: false,
      })
      throw err
    }
  }

  fetchHost = async (hostId: string) => {
    this.setState({
      loadingHost: true,
    })
    try {
      const response = await apiFetchWithAccessToken('GET', config.get('PING_API_URL') + '/hosts/' + hostId)
      this.setState({
        host: response,
        loadingHost: false,
      })
      return response
    } catch (err) {
      this.setState({
        loadingHost: false,
      })
      throw err
    }
  }

  fetchHostResults = async (hostId: string, days: number, start: number) => {
    let response: any
    const cachedResults = getCachedHostResults(hostId, days, start)
    if (cachedResults) {
      response = cachedResults
    } else {
      let query = ''
      if (days) query += '&days=' + days
      if (start) query += '&start=' + start
      this.setState({
        loadingHostResults: true,
      })
      try {
        response = await apiFetchWithAccessToken('GET', config.get('PING_API_URL') + '/hosts/' + hostId + '/results' + (query ? '?' + query.slice(1) : ''))
      } catch (err) {
        this.setState({
          loadingHostResults: false,
        })
        throw err
      }
      setCachedHostResults(hostId, days, start, response)
    }

    const hostResultsMap = {...this.state.hostResultsMap,
      [hostId]: response,
    }

    this.setState({
      hostResults: response,
      hostResultsMap: hostResultsMap,
      loadingHostResults: false,
    })

    return response
  }

  clearHostResults = async () => {
    this.setState({
      hostResults:[],
      hostResultsMap:{},
    })
  }

  clearHostCache = async () => {
    clearCachedHostResults()
  }

  updateHost = async (hostId: string, host: any) => {
    const response = await apiFetchWithAccessToken('PUT', config.get('PING_API_URL') + '/hosts/' + hostId, host)
    this.setState({
      host: response,
    })
    return response
  }

  deleteHost = async (hostId: string) => {
    const response = await apiFetchWithAccessToken('DELETE', config.get('PING_API_URL') + '/hosts/' + hostId)
    return response
  }

  postPaymentNotification = async () => {
    const response = await apiFetchWithAccessToken('POST', config.get('PING_API_URL') + '/payment-notification')
    return response
  }

  render() {
    return (
      <PingContext.Provider value={{ ...this.state,
        fetchHosts: this.fetchHosts,
        fetchHost: this.fetchHost,
        fetchHostResults: this.fetchHostResults,
        clearHostResults: this.clearHostResults,
        clearHostCache: this.clearHostCache,
        updateHost: this.updateHost,
        deleteHost: this.deleteHost,
        postPaymentNotification: this.postPaymentNotification,
      }}>
        {this.props.children}
      </PingContext.Provider>
    )
  }
}

export function withPingContext(Comp: any) {
  return (props: any) => (
    <PingContext.Consumer>
      {(pingContext: PingContext) => <Comp {...props} pingContext={{...pingContext}}/>}
    </PingContext.Consumer>
  )
}
