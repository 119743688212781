import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-pro/css/all.css'
import './app.css'
import './theme.css'
import './dashboard.css'

import config from "react-global-configuration"
import ga from 'react-ga'
import { I18nextProvider } from 'react-i18next'
import i18n from '../i18n'
import { configureSession, SessionStore } from 'clouden-components/context/session'
import { NotificationStore } from 'clouden-components/context/notification'
import { CreditStore } from 'clouden-components/context/credit'
import { SupportStore } from 'clouden-components/context/support'
import { PingStore } from '../context/ping'
import { MailStore } from '../context/mail'
import { StripeProvider } from 'react-stripe-elements'

/**
 * Global configuration for prod or dev based on hostname
 */
config.set(typeof window !== 'undefined' && (window.location.hostname === 'ping.clouden.dev' || window.location.hostname === 'code-ping.clouden.net' || window.location.hostname === 'localhost') ? {
  STAGE: 'dev',
  PING_UI_URL: 'https://ping.clouden.dev',
  PING_API_URL: 'https://ping-api.clouden.dev',
  ACCOUNT_API_URL: 'https://account-api-dev.clouden.net',
  CREDITS_API_URL: 'https://credits-api.clouden.dev',
  MAIL_API_URL: 'https://mail-api-dev.clouden.net',
  MAIL_PING_SERVICE_ID: 'clouden-ping',
  MAIL_CREDITS_SERVICE_ID: 'clouden-credits',
  COGNITO_USER_POOL_ID: "eu-west-1_IemrYjMWc",
  COGNITO_CLIENT_ID: "3le2scvseovnp3i7m48ihtl2qt",
  SUPPORT_API_URL: 'https://support-api-dev.clouden.net',
  SUPPORT_SERVICE_ID: 'clouden-ping',
  CLOUDEN_WEB_URL: 'https://dev.clouden.net',
  STRIPE_KEY: 'pk_test_naReFaizp4iE2DHN6aPZYrHU',
  GA_TRACKING_ID: '',
  MINIMUM_PAYMENT: '1.99',
  MAXIMUM_PAYMENT: '100',
  DEFAULT_SESSION_MODE: 'AUTO_SIGN_UP',
  COMPLETE_AUTO_SIGN_UP_URL: '/dashboard/',
  TAX_PERCENT: '24',
} : {
  STAGE: 'prod',
  PING_UI_URL: 'https://ping.clouden.net',
  PING_API_URL: 'https://ping-api.clouden.net',
  ACCOUNT_API_URL: 'https://account-api.clouden.net',
  CREDITS_API_URL: 'https://credits-api.clouden.net',
  MAIL_API_URL: 'https://mail-api.clouden.net',
  MAIL_PING_SERVICE_ID: 'clouden-ping',
  MAIL_CREDITS_SERVICE_ID: 'clouden-credits',
  COGNITO_USER_POOL_ID: "eu-west-1_Ko5QxeEaD",
  COGNITO_CLIENT_ID: "1pq6a5ch5lcb9lm0pltjv2no5t",
  SUPPORT_API_URL: 'https://support-api.clouden.net',
  SUPPORT_SERVICE_ID: 'clouden-ping',
  CLOUDEN_WEB_URL: 'https://clouden.net',
  STRIPE_KEY: 'pk_live_yyIc8ogLPGq9nM2qTL1s2IUn',
  GA_TRACKING_ID: 'UA-77801665-8',
  MINIMUM_PAYMENT: '1.99',
  MAXIMUM_PAYMENT: '100',
  DEFAULT_SESSION_MODE: 'AUTO_SIGN_UP',
  COMPLETE_AUTO_SIGN_UP_URL: '/dashboard/',
  TAX_PERCENT: '24',
})

if (config.get('STAGE') === 'dev') {
  console.log('Clouden Ping running in development mode.')
}

configureSession(config.get('COGNITO_USER_POOL_ID'), config.get('COGNITO_CLIENT_ID'))

if (typeof window !== 'undefined') {
  const gaTrackingId = config.get('GA_TRACKING_ID')
  if (gaTrackingId) {
    ga.initialize(gaTrackingId)
    ga.set({ anonymizeIp: true })
  }
}

class App extends React.Component {
  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <NotificationStore>
          <SessionStore>
            <CreditStore>
              <SupportStore>
                <PingStore>
                  <MailStore>
                    {typeof window === 'undefined' ? this.props.children :
                    <StripeProvider apiKey={config.get('STRIPE_KEY')}>
                      {this.props.children}
                    </StripeProvider>}
                  </MailStore>
                </PingStore>
              </SupportStore>
            </CreditStore>
          </SessionStore>
        </NotificationStore>
      </I18nextProvider>
    )
  }
}

export default App
